import React, {useState, useCallback, useEffect} from 'react';
import {Input, Spin, Modal, Radio} from 'antd';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../errors';
import Row from '../Widgets/Row';
import Button from '../Widgets/Button';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import {MEMBER_TYPE} from '../dictionary';

export default function MemberSelectDalog(props) {
  const {visible, onClose, selectedId, onChange} = props;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actions] = useOutlet('actions');
  const getUsers = useCallback(
    async (search = '') => {
      setLoading(true);
      try {
        if (search) {
          let resp = await actions.getMembers({
            search,
            offset: 0,
            limit: 10,
          });

          setUsers(resp.results);
        }
      } catch (err) {
        errorHandler(err, '取得使用者列表錯誤');
      }
      setLoading(false);
    },
    [actions],
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="選擇會員"
      footer={
        <Row style={{justifyContent: 'flex-end'}}>
          <Button onClick={onClose}>關閉</Button>
        </Row>
      }>
      <Row style={{marginBottom: 15}}>
        <h4>搜尋會員</h4>
        <Input.Search
          onSearch={getUsers}
          disabled={loading}
          placeholder="搜尋會員(姓名/電話/抬頭/統編/信箱)"
        />
      </Row>

      <Row style={{alignItems: 'flex-start'}}>
        <h4>選擇會員</h4>
        <div style={{flex: 1}}>
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{fontSize: 16}} />}
          />
          {users.map((user, idx) => (
            <Item key={idx} onClick={() => onChange(user)}>
              <div style={{flex: '0 0 50px'}}>
                <Radio checked={selectedId === user.id} />
              </div>
              <div style={{flex: 1}}>
                <div>
                  #{user.id} {user.name}
                </div>
                <div>
                  {MEMBER_TYPE[user.user_type]}{' '}
                  {user.user_type === 'enterprise' &&
                    `（${user.ent_name} / ${user.gui_number}）`}
                </div>

                <div>{user.phone}</div>
                <div>{user.email}</div>
              </div>
            </Item>
          ))}
        </div>
      </Row>
    </Modal>
  );
}

const Item = styled.div`
  border-bottom: solid 1px #ccc;
  display: flex;
  padding-bottom: 15px;
  margin-bottom: 15px;
  cursor: pointer;
`;
