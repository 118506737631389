import React from 'react';
import {KeyboardArrowLeft} from '@styled-icons/material/KeyboardArrowLeft';

import {navigate} from 'gatsby';

export default function BackButton({
  children = '返回',
  pathname = '/',
  extraStyle = {},
  onClick,
}) {
  return (
    <div
      style={{
        color: '#777777',
        cursor: 'pointer',
        maxWidth: 70,
        width: '100%',
        ...extraStyle,
      }}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        } else if (typeof window && window.history.length > 0) {
          window.history.back();
        } else {
          navigate(pathname);
        }
      }}>
      <KeyboardArrowLeft size={18} color={'#777777'} />
      {children}
    </div>
  );
}
