import React from 'react';
import {Divider} from 'antd';
const appConfig = require('../data.json');

export default function OrderCalculation({calculations}) {
  if (!calculations) {
    return null;
  }

  let {
    items_amount = 0, //商品金額（未稅）
    items_tax = 0, //商品金額（稅）
    discount = 0, //活動折抵（未稅）
    discount_tax = 0, //活動折抵（稅）
    fee = 0, //運費(未稅)
    fee_tax = 0, //運費（稅）
    bonus = 0, //紅利（未稅）
    bonus_tax = 0, //紅利（稅）
    amount = 0, //訂單總計金額(含稅)
    feedback_bonus = 0, //可獲得紅利
  } = calculations;

  return (
    <div>
      <div className="row" style={{justifyContent: 'space-between'}}>
        <h4 style={{width: 160}}>商品總額（含稅）：</h4>
        <div>{items_amount + items_tax} 元</div>
      </div>

      <div className="row" style={{justifyContent: 'space-between'}}>
        <h4> - 紅利（含稅）：</h4>
        <div>{bonus + bonus_tax} 元</div>
      </div>

      <div className="row" style={{justifyContent: 'space-between'}}>
        <h4> - 折扣（含稅）：</h4>
        <div>{discount + discount_tax} 元</div>
      </div>

      <div className="row" style={{justifyContent: 'space-between'}}>
        <h4> + 運費（含稅）：</h4>
        <div>{fee + fee_tax} 元</div>
      </div>

      <Divider />

      <div className="row" style={{alignItems: 'flex-end'}}>
        <h4> = 總額：</h4>
        <div style={{flex: 1}} />
        <div
          style={{
            fontSize: 24,
            color: appConfig.colors.main,
            lineHeight: 'normal',
            marginRight: 5,
          }}>
          {' '}
          {amount}{' '}
        </div>
        <div>元</div>
      </div>

      <div className="row" style={{justifyContent: 'space-between'}}>
        <h4>可獲得紅利：</h4>
        {/* order.order_type !== 'credit' */}
        <div>{feedback_bonus} 元</div>
      </div>
    </div>
  );
}
