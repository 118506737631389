import React, {useState, useCallback, useEffect} from 'react';
import {Modal, Input} from 'antd';
import Hint from '../../Widgets/Hint';
import Button from '../../Widgets/Button';
import Row from '../../Widgets/Row';

export const UISTATE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

const defaultValues = {
  item_name: '', /// 員工自填，有字數限制, default 自定義商品
  custom_note: '', // 非用來當作商品名稱，用來填入規格 無字數限制
  item_quantity: 1,
  item_amount: 100, //(含稅)
  item_cost: 0,
  logistic_fee: 0, // default=0
  is_custom: true,
};

export default function CustomItemDialog(props) {
  const {item, visible, onClose, onChange} = props;
  const [uiState, setUiState] = useState(item ? UISTATE.EDIT : UISTATE.CREATE);
  const [values, _setValues] = useState(defaultValues);

  const setValues = useCallback((obj) => {
    _setValues((prev) => ({...prev, ...obj}));
  }, []);

  useEffect(() => {
    setValues(item || defaultValues);
    setUiState(item ? UISTATE.EDIT : UISTATE.CREATE);
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      destroyOnClose
      title={(uiState === UISTATE.CREATE ? '新增' : '編輯') + '商品'}
      footer={
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}} />
          <Button
            onClick={() => {
              onChange(uiState, values);
              onClose();
            }}>
            儲存
          </Button>
        </div>
      }
      width={600}>
      <Row style={{marginBottom: 15}}>
        <h4>商品名稱</h4>
        <Input
          value={values.item_name}
          onChange={(e) => setValues({item_name: e.target.value})}
        />
        <Hint type="info" style={{flexShrink: 0}}>
          30字以內，避免發票開立失敗
        </Hint>
      </Row>

      <Row style={{marginBottom: 15}}>
        <h4>商品規格</h4>
        <Input.TextArea
          value={values.custom_note}
          onChange={(e) => setValues({custom_note: e.target.value})}
        />
      </Row>
      <Row style={{marginBottom: 15}}>
        <h4>數量(整數)</h4>
        <Input
          type="number"
          min="0"
          step="1"
          value={values.item_quantity}
          onChange={(e) => setValues({item_quantity: parseInt(e.target.value)})}
        />
      </Row>
      <Row style={{marginBottom: 15}}>
        <h4>價格（含稅）</h4>
        <Input
          type="number"
          value={values.item_amount}
          onChange={(e) => setValues({item_amount: parseInt(e.target.value)})}
        />
      </Row>
      <Row style={{marginBottom: 15}}>
        <h4>成本</h4>
        <Input
          type="number"
          value={values.item_cost}
          onChange={(e) => setValues({item_cost: parseInt(e.target.value)})}
        />
      </Row>

      <Row style={{marginBottom: 15}}>
        <h4>運費</h4>
        <Input
          type="number"
          value={values.logistic_fee}
          onChange={(e) => setValues({logistic_fee: parseInt(e.target.value)})}
        />
      </Row>
    </Modal>
  );
}
