function isPersonCertificatedCode(string) {
  //台灣自然人憑證(2碼英文字母加上14碼數字)
  var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;
  return regexp.test(string);
}

function isMobileVehicleCode(string) {
  // 手機載具(斜線(/)加上7碼數字或大寫字母)
  var regexp = /^\/[0-9A-Z\+\-\.]{7}$/;
  return regexp.test(string);
}

function isCompanyId(string) {
  //公司統一編號(由8位數字組成)
  var regexp = /^[0-9]{8}$/;
  return regexp.test(string);
}

function isLoveCode(string) {
  //愛心捐贈碼(由3至7位數字組成)
  var regexp = /^[0-9]{3,7}$/;
  return regexp.test(string);
}

function isNotEmpty(value) {
  if (typeof value === 'string') {
    return value.trim();
  }

  return value === 0 || value;
}

function isEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function isMobileNumber(phone) {
  let re = /^([0|+[0-9]{1,5})?([7-9][0-9]{8})$/;
  return re.test(phone);
}

function isNumeric(value) {
  let re = /^[0-9]*$/;
  return re.test(value);
}

function isExceedStringMaxLength(value) {
  let re = {test: (value) => value.length >= 128};
  return re.test(value);
}

function isExceedNameLengthLimit({value, limit}) {
  // 使用正則表達式來判斷是否為 CJK 字元（中文、日文、韓文）或全形符號
  const fullWidthPattern = /[^\u0000-\u00FF]/;

  let count = 0;
  for (let i = 0; i < value.length; i++) {
    const char = value[i];

    if (fullWidthPattern.test(char)) {
      count += 1; // 全形字計為 1 個
    } else {
      count += 0.5; // 半形字計為 0.5 個
    }
  }
  return count > limit;
}

module.exports = {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
  isNotEmpty,
  isEmail,
  isMobileNumber,
  isNumeric,
  isExceedStringMaxLength,
  isExceedNameLengthLimit,
};
