import React from 'react';
import styled from 'styled-components';

export default function IconButton({
  onClick,
  disabled,
  children,
  color = '#c1c1c1',
  size = 20,
  hoverColor = '#d6d6d6',
  ...props
}) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      hoverColor={hoverColor}
      {...props}>
      {React.cloneElement(children, {color, size})}
    </Button>
  );
}

const Button = styled.button`
  border: 0;
  background-color: transparent;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
    & > svg {
      color: ${(props) => props.hoverColor};
    }
  }
  &:disabled {
    cursor: not-allowed;
    & > svg {
      color: #ddd;
    }
  }
`;
