import React, {Component} from 'react';
import styled from 'styled-components';
import {
  ALL_SUPPORTED_RECEIPT_TYPE,
  RECEIPT_TWO_COPIES_TYPES,
  isCompanyId,
  isLoveCode,
  isMobileVehicleCode,
  isPersonCertificatedCode,
} from '../Utils/InvoiceUtil';
import * as Ant from 'antd';
import Row from '../Widgets/Row';
import {ErrInvoice} from '../errors';
import validate from '../Utils/validate';
const appConfig = require('../data.json');
const {isNotEmpty} = validate;

export function validateInvoice(config) {
  let {
    invoice_type,
    invoice_subtype = '',
    citizen_personal_certificate_code = '',
    mobile_vehicle_code = '',
    gui_number = '',
    company_title = '',
    donate_foundation_gui = '',
    love_code = '',
  } = config;

  // invoice config
  if (invoice_type === 'two_copies') {
    if (invoice_subtype === 'citizen_personal_certificate') {
      if (
        !isNotEmpty(citizen_personal_certificate_code) ||
        !isPersonCertificatedCode(citizen_personal_certificate_code)
      ) {
        throw new ErrInvoice(
          '二聯式發票/自然人憑證格式錯誤(2碼英文字母加上14碼數字)',
        );
      }
    } else if (invoice_subtype === 'mobile_vehicle') {
      if (
        !isNotEmpty(mobile_vehicle_code) ||
        !isMobileVehicleCode(mobile_vehicle_code)
      ) {
        throw new ErrInvoice(
          '二聯式發票/手機載具格式錯誤(斜線(/)加上7碼數字或大寫字母)',
        );
      }
    }
  }

  if (invoice_type === 'three_copies') {
    if (
      !isNotEmpty(gui_number) ||
      !isCompanyId(gui_number) ||
      !isNotEmpty(company_title)
    ) {
      throw new ErrInvoice('三聯式發票格式錯誤');
    }
  }

  if (invoice_type === 'donate') {
    if (!isNotEmpty(love_code) || !isLoveCode(love_code)) {
      throw new ErrInvoice('捐贈發票格式錯誤');
    }
  }
}

export default function InvoiceConfig(props) {
  const {setConfig, config} = props;
  const {
    invoice_type: invoiceType = ALL_SUPPORTED_RECEIPT_TYPE.three_copies.value,
    invoice_subtype: invoiceSubType = RECEIPT_TWO_COPIES_TYPES.ezpay_vehicle
      .value,
  } = config;

  return (
    <Wrapper>
      <Ant.Radio
        checked={invoiceType === ALL_SUPPORTED_RECEIPT_TYPE.two_copies.value}
        value={ALL_SUPPORTED_RECEIPT_TYPE.two_copies.value}
        onChange={(e) => {
          setConfig({...config, invoice_type: e.target.value});
        }}>
        二聯式電子發票
      </Ant.Radio>
      {invoiceType === ALL_SUPPORTED_RECEIPT_TYPE.two_copies.value && (
        <div className="radio-content">
          <Row>
            <h4>發票類型</h4>
            <Ant.Select
              defaultValue="member"
              value={invoiceSubType}
              onChange={(value) => {
                setConfig({...config, invoice_subtype: value});
              }}>
              <Ant.Select.Option
                value={RECEIPT_TWO_COPIES_TYPES.ezpay_vehicle.value}>
                {RECEIPT_TWO_COPIES_TYPES.ezpay_vehicle.label}
              </Ant.Select.Option>
              <Ant.Select.Option
                value={
                  RECEIPT_TWO_COPIES_TYPES.citizen_personal_certificate.value
                }>
                {RECEIPT_TWO_COPIES_TYPES.citizen_personal_certificate.label}
              </Ant.Select.Option>
              <Ant.Select.Option
                value={RECEIPT_TWO_COPIES_TYPES.mobile_vehicle.value}>
                {RECEIPT_TWO_COPIES_TYPES.mobile_vehicle.label}
              </Ant.Select.Option>
            </Ant.Select>
          </Row>
          {(() => {
            if (
              invoiceSubType ===
              RECEIPT_TWO_COPIES_TYPES.citizen_personal_certificate.value
            ) {
              return (
                <Row style={{marginTop: 10}}>
                  <h4>條碼編號</h4>
                  <Ant.Input
                    placeholder="請輸入自然人憑證條碼16碼"
                    value={config.citizen_personal_certificate_code}
                    onChange={(e) =>
                      setConfig({
                        ...config,
                        citizen_personal_certificate_code: e.target.value,
                      })
                    }
                  />
                </Row>
              );
            } else if (
              invoiceSubType === RECEIPT_TWO_COPIES_TYPES.mobile_vehicle.value
            ) {
              return (
                <Row style={{marginTop: 10}}>
                  <h4>條碼編號</h4>
                  <Ant.Input
                    placeholder="請輸入手機載具條碼8碼"
                    value={config.mobile_vehicle_code}
                    onChange={(e) =>
                      setConfig({
                        ...config,
                        mobile_vehicle_code: e.target.value,
                      })
                    }
                  />
                </Row>
              );
            }
          })()}
        </div>
      )}
      <Ant.Radio
        checked={invoiceType === ALL_SUPPORTED_RECEIPT_TYPE.three_copies.value}
        value={ALL_SUPPORTED_RECEIPT_TYPE.three_copies.value}
        onChange={(e) => {
          setConfig({...config, invoice_type: e.target.value});
        }}>
        三聯式電子發票
      </Ant.Radio>
      {invoiceType === ALL_SUPPORTED_RECEIPT_TYPE.three_copies.value && (
        <div className="radio-content">
          <Row style={{marginBottom: 10}}>
            <h4>統一編號</h4>
            <Ant.Input
              placeholder="請輸入公司統編"
              value={config.gui_number}
              onChange={(e) =>
                setConfig({...config, gui_number: e.target.value})
              }
            />
          </Row>
          <Row>
            <h4>公司抬頭</h4>
            <Ant.Input
              placeholder="請輸入公司抬頭"
              value={config.company_title}
              onChange={(e) =>
                setConfig({...config, company_title: e.target.value})
              }
            />
          </Row>
        </div>
      )}
      <Ant.Radio
        checked={invoiceType === ALL_SUPPORTED_RECEIPT_TYPE.donate.value}
        value={ALL_SUPPORTED_RECEIPT_TYPE.donate.value}
        onChange={(e) => {
          setConfig({...config, invoice_type: e.target.value});
        }}>
        捐贈發票
      </Ant.Radio>
      {invoiceType === ALL_SUPPORTED_RECEIPT_TYPE.donate.value && (
        <div className="radio-content">
          <Row>
            <h4>愛心碼</h4>
            <Ant.Input
              placeholder="請輸入愛心碼"
              value={config.love_code}
              onChange={(e) =>
                setConfig({...config, love_code: e.target.value})
              }
            />
          </Row>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .ant-radio-wrapper {
    display: block;
    margin-bottom: 15px;
  }

  & .radio-content {
    margin-bottom: 15px;
    margin-left: 24px;
    padding-left: 15px;
    border-left: 1px solid #ddd;
  }
`;
