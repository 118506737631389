export const ALL_SUPPORTED_RECEIPT_TYPE = {
  two_copies: {
    idx: 0,
    label: '二聯式發票',
    value: 'two_copies',
  },
  three_copies: {
    idx: 1,
    label: '三聯式發票',
    value: 'three_copies',
  },
  donate: {
    idx: 2,
    label: '捐贈發票',
    value: 'donate',
  },
};

export function getInvoiceCategory(invoice) {
  return invoice.love_code
    ? '捐贈發票'
    : invoice.category === 'B2B'
    ? '三聯式發票'
    : invoice.category === 'B2C'
    ? '二聯式發票'
    : '';
}

export const RECEIPT_TWO_COPIES_TYPES = {
  citizen_personal_certificate: {
    idx: 0,
    label: '自然人憑證條碼',
    value: 'citizen_personal_certificate',
  },
  mobile_vehicle: {
    idx: 1,
    label: '手機載具',
    value: 'mobile_vehicle',
  },
  ezpay_vehicle: {
    idx: 2,
    label: 'ezPay 電子發票載具',
    value: 'ezpay_vehicle',
  },
};

export function isPersonCertificatedCode(string) {
  //台灣自然人憑證(2碼英文字母加上14碼數字)
  var regexp = /^[a-zA-Z]{2}[0-9]{14}$/;
  return regexp.test(string);
}
export function isMobileVehicleCode(string) {
  // 手機載具(斜線(/)加上7碼數字或大寫字母或+-.)
  let regexp = /^\/[0-9A-Z\+\-\.]{7}$/;
  return regexp.test(string);
}
export function isCompanyId(string) {
  //公司統一編號(由8位數字組成)
  var regexp = /^[0-9]{8}$/;
  return regexp.test(string);
}
export function isLoveCode(string) {
  //愛心捐贈碼(由3至7位數字組成)
  var regexp = /^[0-9]{3,7}$/;
  return regexp.test(string);
}
